
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VerificationDetails, Ticket } from '@/features/om27/types/om27.types';
import { formatDate } from '../utils/datetime';
import OverflowText from '@/components/OverflowText.vue';
import { orderBy } from 'lodash';
import BlockingTickets from '@/features/om27/components/BlockingTickets.vue';

@Component({
  components: {
    OverflowText,
    BlockingTickets,
  },
})
export default class VerificationsTable extends Vue {
  @Prop({ required: true, type: Array }) verificationDetails!: VerificationDetails[];
  @Prop({ required: true, type: String }) timeZone!: string;

  // getter for verificationDetails sorted by noteDate ascending
  get sortedVerificationDetails() {
    const orderedData = orderBy(this.verificationDetails, ['verificationDate'], ['asc']);
    const rows = [];
    for (const verification of orderedData ) {
      rows.push(verification);
      const { comments } = verification;
      if (Array.isArray(comments) && comments.length > 0) {
        const commentsOrdered = orderBy(comments, ['create_dt'], ['asc']);
        rows.push(...commentsOrdered.map((comment) => ({
          verifyHumanUserMudId: comment.verifyCommentHumanUserMudId,
          verifyHumanUserName: comment.verifyCommentHumanUserName,
          verificationNotes: comment.verifycomment,
          verificationStatus: 'COMMENT',
          verificationStatusCode: '',
          verificationDate: comment.create_dt,
        })));
      }
    }
    return rows;
  }

  formatDate(date: string): string {
    return formatDate(date, this.timeZone);
  }

  getTicketsForRow(row: VerificationDetails): Partial<Ticket>[] {
    if (row.ticketNumber) {
      return [{
        ticketNumber: row.ticketNumber,
        ticketUrl: row.ticketUrl,
      }];
    }
    return [];
  }
}
